import React, { useState, useEffect } from "react"

import { firebase } from "../../../../firebase/client"
import { AuthContext } from "./context"

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null)
  const [status, setStatus] = useState("authenticating")
  const [isSuperAdmin, setSuperAdmin] = useState(false)
  const [isAdmin, setAdmin] = useState(false)
  const [token, setToken] = useState(null)

  useEffect(() => {
    return firebase.auth().onIdTokenChanged(user => {
      if (!user) {
        setUser(null)
        setStatus("unauthenticated")
        return
      }

      user.getIdTokenResult().then(idTokenResult => {
        setUser(user)
        setStatus("authenticated")

        // Set the super admin flag
        if (idTokenResult) {
          if (idTokenResult.claims) {
            setSuperAdmin(idTokenResult.claims.super_admin)
            setAdmin(idTokenResult.claims.admin)
          }

          // If token then set it
          if (idTokenResult.token) {
            setToken(idTokenResult.token)
          }
        }
      })
    })
  }, [])

  return (
    <AuthContext.Provider
      value={{ user, status, isSuperAdmin, isAdmin, token }}
    >
      {children}
    </AuthContext.Provider>
  )
}
