/**
 * Pending Invitations Table
 */
import React, { useCallback, useEffect, useState } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import {
  Button,
  CircularProgress,
  Flex,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import { DeleteIcon } from "@chakra-ui/icons"
import { Table, Thead, Tr, Th, Td, Tbody } from "../table"
import { firebase } from "../../../firebase/client"
import { DeletePrompt } from "../prompt/DeletePrompt"
import { useAuth } from "../../providers/firebase/auth"

export function InvitationsTable({ limit }) {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [activeInvite, setActiveInvite] = useState(null)
  const [resending, setResending] = useState(false)

  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isAdmin } = useAuth()
  const toast = useToast()

  function openPrompt(invite) {
    onOpen()
    setActiveInvite(invite)
  }

  function closePrompt() {
    onClose()
    setActiveInvite(null)
  }

  const getData = useCallback(async () => {
    const db = firebase.firestore()

    try {
      const query = limit
        ? db.collection("invites").limit(limit).get()
        : db.collection("invites").get()

      const invites = await query
      const inviteData = invites.docs.map(async doc => {
        const invite = doc.data()

        if (invite.group) {
          const ref = invite.group.path

          try {
            const res = await db.doc(ref).get()
            return {
              ...invite,
              id: doc.id,
              group: {
                ...res.data(),
                id: res.id,
              },
            }
          } catch (err) {
            console.error("Unable to get group", err)
          }
        } else {
          return {
            ...invite,
            id: doc.id,
          }
        }
      })

      Promise.all(inviteData).then(items => {
        setData(items)
        setLoading(false)
      })
    } catch (err) {
      console.error("Unable to fetch data", err)
    }
  }, [limit])

  async function handleDelete() {
    const db = firebase.firestore()
    const { id } = activeInvite

    setLoading(true)

    try {
      await db.collection("invites").doc(id).delete()
      onClose()
      await getData()
    } catch (err) {
      console.error("There was a problem deleting the record", err)
      setLoading(false)
      // Show toast
    }
  }

  const resendInvite = async invite => {
    const showError = () => {
      setResending(false)
      toast({
        title: "Oops there was a problem.",
        description:
          "Sorry we were unable to send the email invitation. Please try again and contact support if the problem persists.",
        status: "error",
        duration: 9000,
        isClosable: true,
      })
    }

    setResending(true)

    try {
      const res = await fetch("/.netlify/functions/send-email-invite", {
        method: "POST",
        body: JSON.stringify({
          invite_id: invite.id,
          email: invite.email,
          given_name: invite.given_name,
          invite_type: invite.access_areas.includes("dashboard")
            ? "dashboard"
            : "website",
        }),
      })

      if (res.ok) {
        console.log("invite sent")
        setResending(false)
        toast({
          title: "Email invitation resent.",
          description: "Your invitation was resent successfully.",
          status: "success",
          duration: 9000,
          isClosable: true,
        })
      } else {
        console.error("Unable to send invite email: ", res)
        showError()
      }
    } catch (err) {
      console.error("Unable to send invite email: ", err)
      showError()
    }
  }

  useEffect(() => {
    async function _getData() {
      await getData()
    }

    _getData()

    return () => {}
  }, [getData])

  if (loading) {
    return (
      <Flex flexDir="column" justify="center" align="center">
        <CircularProgress isIndeterminate size={10} thickness={5} />
        <Text mt={4}>Fetching Invitations</Text>
      </Flex>
    )
  }

  if (data.length === 0) {
    return <Text>There are no pending invitations</Text>
  }

  return (
    <>
      <Table>
        <Thead>
          <Tr>
            <Th>ID</Th>
            <Th>Given Name</Th>
            <Th>Family Name</Th>
            <Th>Email Address</Th>
            <Th>Group</Th>
            <Th>Areas</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>

        <Tbody>
          {data.map(invite => {
            return (
              <Tr key={invite.id}>
                <Td>{invite.id}</Td>
                <Td>{invite.given_name}</Td>
                <Td>{invite.family_name}</Td>
                <Td>{invite.email}</Td>
                <Td>{invite.group ? invite.group.name : "-"}</Td>
                <Td>
                  {invite.access_areas.map(area => (
                    <Text key={area} textTransform="capitalize">
                      {area}
                    </Text>
                  ))}
                </Td>
                <Td>
                  <Flex justifyContent="space-between">
                    <Button
                      colorScheme="blue"
                      mr={4}
                      onClick={() => resendInvite(invite)}
                      isLoading={resending}
                      loadingText="Please wait..."
                    >
                      Resend
                    </Button>

                    {isAdmin && (
                      <Button
                        colorScheme="pink"
                        onClick={() => openPrompt(invite)}
                      >
                        <DeleteIcon />
                      </Button>
                    )}
                  </Flex>
                </Td>
              </Tr>
            )
          })}
        </Tbody>
      </Table>

      {limit && (
        <Flex justify="center" align="center" mt={8}>
          <Button colorScheme="blue" as={Link} to="/app/invitations">
            View All Invitations
          </Button>
        </Flex>
      )}

      {activeInvite && (
        <DeletePrompt
          isOpen={isOpen}
          onClose={closePrompt}
          title="Are you sure?"
          handleOk={handleDelete}
        >
          Are you sure you want to delete the invitation for{" "}
          {activeInvite.given_name} {activeInvite.family_name}?
        </DeletePrompt>
      )}
    </>
  )
}

InvitationsTable.defaultProps = {
  limit: null,
}

InvitationsTable.propTypes = {
  limit: PropTypes.number,
}
