import React, { useEffect, useState } from "react"
import { Router } from "@reach/router"
import { navigate } from "gatsby"
import { useAuth } from "../providers/firebase/auth"
import { Layout } from "../components/layout"
import { PageLoader } from "../components/page-loader"
import { Dashboard } from "../components/dashboard/Dashboard"
import { FormEnquiries } from "../components/dashboard/FormEnquiries"
import { Enquiries } from "../components/dashboard/Enquiries"
import { Groups } from "../components/dashboard/Groups"
import { Group } from "../components/dashboard/Group"
import { Invitations } from "../components/dashboard/Invitations"
import { ProfilePage } from "../components/dashboard/ProfilePage"
import { Users } from "../components/dashboard/Users"
import { User } from "../components/dashboard/User"
import PrivateRoute from "../components/dashboard/PrivateRoute"

function WelcomePage() {
  const [loading, setLoading] = useState(true)
  const { user, status } = useAuth()

  useEffect(() => {
    if (status === "unauthenticated") {
      navigate("/")
    } else if (status === "authenticated") {
      setLoading(false)
    }
  }, [status])

  if (loading || !user) {
    return <PageLoader />
  }

  return (
    <Layout>
      <Router basepath="/app">
        <PrivateRoute path="/dashboard" component={Dashboard} />
        <PrivateRoute path="/groups" component={Groups} />
        <PrivateRoute path="/group/:id" component={Group} />
        <PrivateRoute path="/invitations" component={Invitations} />
        <PrivateRoute path="/users" component={Users} />
        <PrivateRoute path="/user/:id" component={User} />
        <PrivateRoute path="/enquiries" component={Enquiries} />
        <PrivateRoute path="/enquiries/:slug" component={FormEnquiries} />
        <PrivateRoute path="/profile" component={ProfilePage} />
      </Router>
    </Layout>
  )
}

export default WelcomePage
