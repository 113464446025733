import React from "react"
import { Box, Text, Flex } from "@chakra-ui/react"
import { navigate } from "@reach/router"
import SEO from "../seo"

export const Enquiries = () => {
  const forms = [
    {
      title: "UK Enquiry Form",
      id: "uk_enquiries",
    },
    {
      title: "USA Enquiry Form",
      id: "usa_enquiries",
    },
    {
      title: "India Enquiry Form",
      id: "india_enquiries",
    },
    {
      title: "Product Literature Downloads",
      id: "literature_downloads",
    },
  ]

  return (
    <>
      <SEO title="Micropore | Groups" />

      <Box p={8}>
        <Flex justify="space-between" mb={6}>
          <Box as="h2" fontSize={24} fontWeight="medium">
            Enquries
          </Box>
        </Flex>

        <Text>Please select the form that you wish to view.</Text>
      </Box>

      <Box p={8} pt={0}>
        {forms.map(form => {
          return (
            <Flex
              role="button"
              key={form.id}
              borderTopColor="gray.100"
              borderTopStyle="solid"
              borderTopWidth={2}
              cursor="pointer"
              onClick={() => navigate(`/app/enquiries/${form.id}`)}
              py={4}
              _hover={{ color: "blue.500" }}
            >
              <Text fontSize={18} fontWeight={500}>
                {form.title}
              </Text>
            </Flex>
          )
        })}
      </Box>
    </>
  )
}
