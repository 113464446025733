import React from "react"
import { Link } from "@reach/router"
import { Box, Button, SimpleGrid, Text } from "@chakra-ui/react"
import { InvitationsTable } from "../data-table/Invitations"
import SEO from "../seo"

export function Dashboard() {
  return (
    <>
      <SEO title="Micropore | Dashboard" />
      <SimpleGrid
        columns={3}
        spacing={16}
        backgroundColor="gray.50"
        py={12}
        px={8}
      >
        <Box
          borderWidth="1px"
          borderRadius="lg"
          overflow="hidden"
          boxShadow="0px 0px 14px rgba(0, 0, 0, .1)"
          backgroundColor="white"
        >
          <Box p="6">
            <Box
              fontWeight="semibold"
              as="h2"
              lineHeight="tight"
              fontSize={18}
              marginBottom={4}
            >
              Groups
            </Box>

            <Text as="p" lineHeight="tight" mb={4}>
              Groups are a collection of users. Use groups to assign pages to
              users within a group.
            </Text>

            <Button colorScheme="blue" as={Link} to="/app/groups">
              Go To Groups
            </Button>
          </Box>
        </Box>

        <Box
          borderWidth="1px"
          borderRadius="lg"
          overflow="hidden"
          boxShadow="0px 0px 14px rgba(0, 0, 0, .1)"
          backgroundColor="white"
        >
          <Box p="6">
            <Box
              fontWeight="semibold"
              as="h2"
              lineHeight="tight"
              fontSize={18}
              marginBottom={4}
            >
              Users
            </Box>

            <Text as="p" lineHeight="tight" mb={4}>
              Groups are a collection of users. Use groups to assign pages to
              users within a group.
            </Text>

            <Button colorScheme="blue" as={Link} to="/app/users">
              Go To Users
            </Button>
          </Box>
        </Box>

        <Box
          borderWidth="1px"
          borderRadius="lg"
          overflow="hidden"
          boxShadow="0px 0px 14px rgba(0, 0, 0, .1)"
          backgroundColor="white"
        >
          <Box p="6">
            <Box
              fontWeight="semibold"
              as="h2"
              lineHeight="tight"
              fontSize={18}
              marginBottom={4}
            >
              Invitations
            </Box>

            <Text as="p" lineHeight="tight" mb={4}>
              Groups are a collection of users. Use groups to assign pages to
              users within a group.
            </Text>

            <Button colorScheme="blue" as={Link} to="/app/invitations">
              Go To Invitations
            </Button>
          </Box>
        </Box>
      </SimpleGrid>

      <Box p={8}>
        <Box as="h2" fontSize={24} fontWeight="medium" mb={6}>
          Recent Invitations
        </Box>

        <InvitationsTable limit={10} />
      </Box>
    </>
  )
}
