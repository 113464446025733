import React from "react"
import { LoginForm } from "../components/forms/login"
import SEO from "../components/seo"

function IndexPage() {
  return (
    <>
      <SEO title="Micropore" />
      <LoginForm />
    </>
  )
}

export default IndexPage
