import React, { useState } from "react"
import { navigate } from "gatsby"
import { useFormik } from "formik"
import {
  Box,
  Button,
  ScaleFade,
  Flex,
  FormControl,
  FormLabel,
  Input,
  VStack,
} from "@chakra-ui/react"

import { firebase } from "../../../firebase/client"

export function LoginForm() {
  const [loading, setLoading] = useState(false)
  const [firebaseError, setFirebaseError] = useState(null)

  async function login({ email, password }) {
    setLoading(true)

    try {
      const res = await firebase
        .auth()
        .signInWithEmailAndPassword(email, password)

      const db = firebase.firestore()
      const doc = await db.collection("users").doc(res.user.uid).get()

      if (doc.data().access_areas.includes("dashboard")) {
        navigate("/app/dashboard")
      } else {
        setFirebaseError(
          "Sorry, your account does not have the correct permissions to log in."
        )
        setLoading(false)
      }
    } catch (err) {
      setFirebaseError(err.message)
      setLoading(false)

      // Logging
      console.error("Unable to login: ", err)
    }
  }

  const formik = useFormik({
    onSubmit: values => login(values),
    initialValues: {
      email: "",
      password: "",
    },
  })

  return (
    <Flex
      height="100vh"
      width="100%"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      backgroundColor="gray.20"
    >
      <Box mb={8}>
        <img src="/micropore-logo.png" alt="Micropore Logo" width={180} />
      </Box>

      <ScaleFade initialScale={0.9} in={true}>
        <Box
          width="350px"
          boxShadow="0px 0px 14px rgba(0, 0, 0, .15)"
          padding="40px"
          borderRadius="5px"
        >
          {firebaseError && (
            <Box
              bg="red.100"
              color="red.500"
              p={4}
              mb={4}
              borderRadius="5px"
              fontWeight="600"
            >
              {firebaseError}
            </Box>
          )}

          <form onSubmit={formik.handleSubmit}>
            <VStack spacing="24px">
              <FormControl id="email">
                <FormLabel htmlFor="email">Email address</FormLabel>
                <Input
                  type="email"
                  onChange={formik.handleChange}
                  id="email"
                  name="email"
                  value={formik.values.email}
                />
              </FormControl>

              <FormControl id="password">
                <FormLabel htmlFor="password">Password</FormLabel>
                <Input
                  type="password"
                  onChange={formik.handleChange}
                  id="password"
                  name="password"
                  value={formik.values.password}
                />
              </FormControl>

              <Button
                colorScheme="blue"
                color="white"
                size="md"
                isFullWidth
                type="submit"
                disabled={loading}
                isLoading={loading}
                loadingText="Please Wait"
              >
                Login
              </Button>
            </VStack>
          </form>
        </Box>
      </ScaleFade>
    </Flex>
  )
}
