import React from "react"
import { Box, Flex } from "@chakra-ui/react"
import SEO from "../seo"
import { UsersTable } from "../data-table/Users"

export function Users() {
  return (
    <>
      <SEO title="Micropore | Users" />

      <Box p={8}>
        <Flex justify="space-between" mb={6}>
          <Box as="h2" fontSize={24} fontWeight="medium">
            Users
          </Box>
        </Flex>

        <UsersTable />
      </Box>
    </>
  )
}
