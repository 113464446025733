import React, { useState } from "react"
import {
  Box,
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  Flex,
  useDisclosure,
} from "@chakra-ui/react"
import SEO from "../seo"
import { InvitationsTable } from "../data-table/Invitations"
import { CreateInviteForm } from "../forms/create-invite"
import { PageLoader } from "../page-loader"
import { useAuth } from "../../providers/firebase/auth"

export function Invitations() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isAdmin } = useAuth()
  const [loading, setLoading] = useState()

  function handleComplete() {
    setLoading(true)
    onClose()

    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }

  if (loading) return <PageLoader />

  return (
    <>
      <SEO title="Micropore | Invitations" />

      <Box p={8}>
        <Flex justify="space-between" mb={6}>
          <Box as="h2" fontSize={24} fontWeight="medium">
            Invitations
          </Box>

          {isAdmin && (
            <Button colorScheme="blue" onClick={onOpen}>
              Create Invitation
            </Button>
          )}
        </Flex>

        <InvitationsTable />
      </Box>

      <Drawer placement="left" onClose={onClose} isOpen={isOpen} size="md">
        <DrawerOverlay>
          <DrawerContent>
            <DrawerHeader borderBottomWidth="1px">
              Create Invitation
            </DrawerHeader>
            <DrawerBody>
              <CreateInviteForm onComplete={handleComplete} />
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
  )
}
