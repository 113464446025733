import React, { useEffect, useState } from "react"
import { Formik, Form, Field } from "formik"
import {
  Button,
  Box,
  Flex,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  VStack,
  Select,
  SimpleGrid,
  Stack,
  useToast,
} from "@chakra-ui/react"

import { firebase } from "../../../firebase/client"

export function CreateInviteForm({ onComplete }) {
  const [loading, setLoading] = useState(false)
  const [groups, setGroups] = useState([])
  const toast = useToast()

  async function createInvite(values) {
    const db = firebase.firestore()

    setLoading(true)

    // Create new entry
    try {
      const invite = await db.collection("invites").add({
        ...values,
        group: db.doc(`groups/${values.group}`),
      })

      // Call the email
      try {
        const res = await fetch("/.netlify/functions/send-email-invite", {
          method: "POST",
          body: JSON.stringify({
            invite_id: invite.id,
            email: values.email,
            given_name: values.given_name,
            invite_type: values.access_areas.includes("dashboard")
              ? "dashboard"
              : "website",
          }),
        })

        if (res.ok) {
          console.log("invite sent")
        } else {
          console.error("Unable to send invite email: ", res)
          toast({
            title: "Oops there was a problem.",
            description:
              "There was a problem creating the invitation. Please try again and continue support if the error persists.",
            status: "error",
            duration: 9000,
            isClosable: true,
          })
        }
      } catch (err) {
        console.error("Unable to send invite email: ", err)
        toast({
          title: "Oops there was a problem.",
          description:
            "There was a problem creating the invitation. Please try again and continue support if the error persists.",
          status: "error",
          duration: 9000,
          isClosable: true,
        })
      }

      onComplete()
    } catch (err) {
      console.error("Unable to create invitation: ", err)
      setLoading(false)
      toast({
        title: "Oops there was a problem.",
        description:
          "There was a problem creating the invitation. Please try again and continue support if the error persists.",
        status: "error",
        duration: 9000,
        isClosable: true,
      })
    }
  }

  useEffect(() => {
    async function getGroups() {
      const db = firebase.firestore()

      try {
        const res = await db.collection("groups").get()
        const docs = res.docs.map(doc => {
          return {
            id: doc.id,
            ...doc.data(),
          }
        })

        setGroups(docs)
      } catch (err) {
        console.error("there was a problem", err)
      }
    }

    getGroups()
  }, [])

  const formikConfig = {
    onSubmit: values => createInvite(values),
    initialValues: {
      given_name: "",
      family_name: "",
      email: "",
      group: "",
      access_areas: ["website"],
    },
  }

  return (
    <Formik {...formikConfig}>
      {({ values, handleChange }) => (
        <Form>
          <SimpleGrid columns={2} spacing={4} mb={4}>
            <FormControl id="given_name">
              <FormLabel htmlFor="given_name">Given Name</FormLabel>
              <FormHelperText mb={4}>
                What is the user's given name?
              </FormHelperText>
              <Input
                type="text"
                onChange={handleChange}
                id="given_name"
                name="given_name"
                value={values.given_name}
              />
            </FormControl>

            <FormControl id="family_name">
              <FormLabel htmlFor="family_name">Family Name</FormLabel>
              <FormHelperText mb={4}>
                What is the user's family name?
              </FormHelperText>
              <Input
                type="text"
                onChange={handleChange}
                id="family_name"
                name="family_name"
                value={values.family_name}
              />
            </FormControl>
          </SimpleGrid>

          <VStack spacing="24px">
            <FormControl id="email">
              <FormLabel htmlFor="email">Email Address</FormLabel>
              <FormHelperText mb={4}>
                What is the user's email address?
              </FormHelperText>
              <Input
                type="email"
                onChange={handleChange}
                id="email"
                name="email"
                value={values.email}
              />
            </FormControl>

            <FormControl id="group">
              <FormLabel htmlFor="group">Group</FormLabel>
              <FormHelperText mb={4}>
                Which group will this user belong to?
              </FormHelperText>

              <Select
                placeholder=""
                name="group"
                id="group"
                onChange={handleChange}
                value={values.group}
              >
                <option value="" disabled>
                  Please Select
                </option>
                {groups.length > 0 &&
                  groups.map(group => (
                    <option key={group.id} value={group.id}>
                      {group.name}
                    </option>
                  ))}
              </Select>
            </FormControl>

            <FormControl id="access_areas" mb={6}>
              <FormLabel htmlFor="access_areas">Access</FormLabel>
              <FormHelperText mb={4}>
                Select which parts of the platform this user should be able to
                access.
              </FormHelperText>

              <Stack spacing={10} direction="row">
                <Flex>
                  <Box mr={4}>
                    <label htmlFor="access_areas_website">
                      <Field
                        id="access_areas_website"
                        type="checkbox"
                        name="access_areas"
                        value="website"
                      />
                      &nbsp; Website
                    </label>
                  </Box>
                  <Box>
                    <label htmlFor="access_areas_dash">
                      <Field
                        type="checkbox"
                        name="access_areas"
                        value="dashboard"
                        id="access_areas_dash"
                      />
                      &nbsp; Dashboard
                    </label>
                  </Box>
                </Flex>
              </Stack>
            </FormControl>
          </VStack>

          <Button
            colorScheme="blue"
            color="white"
            size="md"
            isFullWidth
            type="submit"
            disabled={loading}
            isLoading={loading}
            loadingText="Please Wait"
          >
            Create Invitation
          </Button>
        </Form>
      )}
    </Formik>
  )
}
