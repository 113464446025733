import * as React from "react"
import {
  Box,
  Flex,
  FormLabel,
  FormHelperText,
  Input,
  StackDivider,
  Text,
  VStack,
  Tag,
  TagCloseButton,
  TagLabel,
  Spinner,
  Button,
} from "@chakra-ui/react"
import { debounce } from "lodash"
import { CloseIcon } from "@chakra-ui/icons"
import { client } from "../../lib/sanity"

// Use this component to lookup pages with sanity
export const PageLookup = ({ selectionCallback, presetPages }) => {
  const [searchTerm, setSearchTerm] = React.useState("")
  const [pages, setPages] = React.useState([])
  const [results, setResults] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [showClose, setShowClose] = React.useState(false)
  const [noResults, setNoResults] = React.useState(false)

  const getQuery = React.useCallback(
    debounce(q => sendQuery(q), 500),
    []
  )

  // Preset pages
  // Populate the pages array with pages from firebase
  React.useEffect(() => {
    if (presetPages && presetPages.length > 0) {
      setPages(presetPages)
    }
  }, [])

  // When `pages[]` changes, call the selection callback method so that
  // the firebase payload has the correct data
  React.useEffect(() => {
    if (selectionCallback) {
      selectionCallback(pages)
    }
  }, [pages])

  const sendQuery = async term => {
    const params = { term: `${term}*` }
    const query = `*[_type == 'page' && title match $term && isRestricted == true]{_id, title, slug, parentPage->{_id, title, slug}}`

    setNoResults(true)
    setLoading(true)

    try {
      const res = await client.fetch(query, params)

      if (res) {
        setResults(res)
        setLoading(false)
        setShowClose(true)

        // No results
        if (res.length === 0) {
          setNoResults(true)
        } else {
          setNoResults(false)
        }
      } else {
        console.warn("No results in sendQuery()", res)
        setLoading(false)
        setShowClose(false)
      }
    } catch (err) {
      console.error("Error in sendQuery()", err)
      setLoading(false)
      setShowClose(false)
    }
  }

  const handleChange = e => {
    setSearchTerm(e.target.value)

    if (e.target.value.length >= 3) {
      if (results.length > 0) {
        setResults([])
      }

      getQuery(e.target.value)
    }
  }

  const handleSelection = page => {
    setPages([...pages, page])
  }

  const removePage = page => {
    const newPages = pages.filter(p => p._id !== page._id)
    setPages(newPages)
  }

  return (
    <>
      {pages.length > 0 && (
        <>
          <FormLabel>Selected Pages</FormLabel>
          <FormHelperText mb={4}>
            These are protected pages that members of this group can visit when
            signed in.
          </FormHelperText>

          <Flex flexWrap="wrap" mb={4}>
            {pages.map(page => {
              return (
                <Tag key={page._id} mb={2} mr={3}>
                  <TagLabel>{page.title}</TagLabel>
                  <TagCloseButton onClick={() => removePage(page)} />
                </Tag>
              )
            })}
          </Flex>
        </>
      )}

      <FormLabel htmlFor="name">Add Pages</FormLabel>
      <FormHelperText mb={4}>
        Find the restricted page you would like to add to this group. You can
        add a page by clicking the page in the results below. Selected pages
        will be indicated in the section above.
      </FormHelperText>

      <Box position="relative">
        <Input
          flex={1}
          type="text"
          onChange={handleChange}
          id="name"
          name="name"
          value={searchTerm}
          onFocus={() => {
            if (searchTerm.length >= 3) {
              getQuery(searchTerm)
            }
          }}
        />

        {loading && (
          <Box width="20px" position="absolute" right={4} top={2}>
            <Spinner color="blue.500" />
          </Box>
        )}

        {!loading && showClose && (
          <Box width="20px" position="absolute" right={6} top={0}>
            <Button
              onClick={() => {
                setResults([])
                setSearchTerm("")
                setShowClose(false)
              }}
              height="24px"
              width="16px"
              position="relative"
              top="6px"
              zIndex="10"
              backgroundColor="transparent"
              color="gray.500"
              _hover={{
                backgroundColor: "transparent",
                opacity: 0.75,
                color: "blue.600",
              }}
            >
              <CloseIcon />
            </Button>
          </Box>
        )}
      </Box>

      {!loading && searchTerm.length >= 3 && (results.length > 0 || noResults) && (
        <Box
          boxShadow="md"
          borderRadius="md"
          mt={6}
          p={4}
          width="100%"
          borderColor="gray.100"
          borderWidth="1px"
          maxHeight="245px"
          overflowY="auto"
        >
          <VStack
            spacing={8}
            align="stretch"
            divider={
              <StackDivider
                borderColor="gray.100"
                style={{ marginTop: "6px", marginBottom: "6px" }}
              />
            }
          >
            {noResults ? (
              <Text>We couldn't find any pages that matched your query.</Text>
            ) : (
              results.map(result => {
                const selected = pages.find(page => page._id === result._id)

                return (
                  <Text
                    key={result._id}
                    role="button"
                    aria-label="Click here to open your search result"
                    onClick={
                      selected ? () => {} : () => handleSelection(result)
                    }
                    cursor={selected ? "not-allowed" : "cursor"}
                    opacity={selected ? 0.5 : 1}
                    _hover={{ color: "gray.500" }}
                  >
                    {result.title}
                  </Text>
                )
              })
            )}
          </VStack>
        </Box>
      )}
    </>
  )
}
