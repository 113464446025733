import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import { useAuth } from "../../providers/firebase/auth"
import { PageLoader } from "../page-loader"

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const [loading, setLoading] = useState(true)
  const { user, status } = useAuth()

  useEffect(() => {
    if (status === "unauthenticated") {
      navigate("/")
    } else if (status === "authenticated") {
      setLoading(false)
    }
  }, [status])

  if (loading || !user) {
    return <PageLoader />
  }

  return <Component {...rest} />
}
export default PrivateRoute
