import React, { useState } from "react"
import { useFormik } from "formik"
import {
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  VStack,
  Textarea,
} from "@chakra-ui/react"

import { firebase } from "../../../firebase/client"

export function CreateGroupForm({ onComplete }) {
  const [loading, setLoading] = useState(false)

  async function createGroup({ name, description }) {
    const db = firebase.firestore()

    setLoading(true)

    // Create new entry
    try {
      await db.collection("groups").doc().set({
        name,
        description,
      })

      onComplete()
    } catch (err) {
      console.error("Unable to create group: ", err)
      setLoading(false)
    }
  }

  const formik = useFormik({
    onSubmit: values => createGroup(values),
    initialValues: {
      name: "",
      description: "",
    },
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <VStack spacing="24px">
        <FormControl id="name">
          <FormLabel htmlFor="name">Group Name</FormLabel>
          <FormHelperText mb={4}>What is the name of the group?</FormHelperText>
          <Input
            type="text"
            onChange={formik.handleChange}
            id="name"
            name="name"
            value={formik.values.name}
          />
        </FormControl>

        <FormControl id="description">
          <FormLabel htmlFor="description">Description</FormLabel>
          <FormHelperText mb={4}>
            Enter a description about the group.
          </FormHelperText>
          <Textarea
            onChange={formik.handleChange}
            id="description"
            name="description"
            value={formik.values.description}
          />
        </FormControl>

        <Button
          colorScheme="blue"
          color="white"
          size="md"
          isFullWidth
          type="submit"
          disabled={loading}
          isLoading={loading}
          loadingText="Please Wait"
        >
          Create Group
        </Button>
      </VStack>
    </form>
  )
}
