import React, { useCallback, useEffect, useState } from "react"
import format from "date-fns/format"
import { Link } from "@reach/router"
import { CSVLink } from "react-csv"
import { ArrowBackIcon, DownloadIcon } from "@chakra-ui/icons"
import {
  Box,
  Button,
  Text,
  Flex,
  CircularProgress,
  AccordionItem,
  Accordion,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  List,
  ListItem,
} from "@chakra-ui/react"

import { firebase } from "../../../firebase/client"

import SEO from "../seo"

export const FormEnquiries = ({ slug }) => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)

  const forms = [
    {
      title: "UK Enquiry Form",
      id: "uk_enquiries",
    },
    {
      title: "USA Enquiry Form",
      id: "usa_enquiries",
    },
    {
      title: "India Enquiry Form",
      id: "india_enquiries",
    },
    {
      title: "Product Literature Downloads",
      id: "literature_downloads",
    },
  ]

  const getData = useCallback(async () => {
    const db = firebase.firestore()

    try {
      const query = db.collection(slug).orderBy("createdAt", "asc").get()
      const groups = await query
      const formData = []

      groups.forEach(doc => {
        formData.push({
          ...doc.data(),
          id: doc.id,
        })
      })

      setData(formData)
      setLoading(false)
    } catch (err) {
      console.error("Unable to fetch data", err)
    }
  }, [slug])

  useEffect(() => {
    async function _getData() {
      await getData()
    }

    _getData()

    return () => {}
  }, [getData])

  if (loading) {
    return (
      <Flex flexDir="column" justify="center" align="center" height="100vh">
        <CircularProgress isIndeterminate size={10} thickness={5} />
        <Text mt={4}>Fetching Enquiries</Text>
      </Flex>
    )
  }

  return (
    <>
      <SEO title="Micropore | Enquiries" />

      <Box p={8}>
        <Flex justifyContent="space-between" alignItems="center" mb={8}>
          <Button as={Link} to="/app/enquiries" leftIcon={<ArrowBackIcon />}>
            Go Back to Enquiries
          </Button>

          <Button
            rightIcon={<DownloadIcon />}
            filename={`${slug}_data_export.csv`}
            variant="solid"
            colorScheme="blue"
            as={CSVLink}
            data={data.map(item => {
              const timestamp = item.createdAt
                ? new firebase.firestore.Timestamp(
                    item.createdAt.seconds,
                    item.createdAt.nanoseconds
                  )
                : null

              const date = timestamp ? timestamp.toDate() : null

              return {
                enquiry_date: format(date, "PPpp"),
                name: `${item.given_name} ${item.family_name}`,
                email: item.email,
                contact_number: item.contact_number,
                message: item.message,
                email_consent: item.email_consent,
                region: item.region,
              }
            })}
          >
            Export to CSV
          </Button>
        </Flex>

        <Flex justify="space-between" mb={6}>
          <Box as="h2" fontSize={24} fontWeight="medium">
            Enquries: {forms.find(form => form.id === slug).title}
          </Box>

          <Box as="h3" fontSize={24} fontWeight="medium">
            Total Count: {data.length}
          </Box>
        </Flex>

        {data.length > 0 && (
          <Accordion allowToggle>
            {data.map(item => {
              const timestamp = item.createdAt
                ? new firebase.firestore.Timestamp(
                    item.createdAt.seconds,
                    item.createdAt.nanoseconds
                  )
                : null

              const date = timestamp ? timestamp.toDate() : null

              return (
                <AccordionItem key={item.id}>
                  <h2>
                    <AccordionButton>
                      <Flex
                        flex="1"
                        textAlign="left"
                        justifyContent="space-between"
                      >
                        {slug === "literature_downloads" ? (
                          <Text>
                            Download Request from {item.given_name}{" "}
                            {item.family_name}
                          </Text>
                        ) : (
                          <Text>
                            General Enquiry from {item.given_name}{" "}
                            {item.family_name}
                          </Text>
                        )}

                        {date && <Text mr={4}>{format(date, "PPpp")}</Text>}
                      </Flex>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>

                  <AccordionPanel pb={4}>
                    {slug === "literature_downloads" ? (
                      <>
                        <Box mb={4}>
                          <Text fontWeight="bold">Customer Email Address:</Text>
                          <Text>{item.email}</Text>
                        </Box>

                        <Box>
                          <Text fontWeight="bold">Requested Downloads:</Text>

                          <List>
                            {item.selected_downloads.map((download, index) => {
                              return (
                                <ListItem key={`download-${index}`}>
                                  {download.label}
                                </ListItem>
                              )
                            })}
                          </List>
                        </Box>
                      </>
                    ) : (
                      <>
                        <Text fontWeight="bold">Message:</Text>
                        <Text mb={4}>{item.message}</Text>

                        <Text fontWeight="bold">Contact Information</Text>

                        <List>
                          <ListItem>
                            Contact Number: {item.contact_number}
                          </ListItem>
                          <ListItem>
                            Email Consent: {item.email_consent}
                          </ListItem>
                          <ListItem>
                            Phone Consent: {item.phone_consent}
                          </ListItem>
                        </List>
                      </>
                    )}
                  </AccordionPanel>
                </AccordionItem>
              )
            })}
          </Accordion>
        )}
      </Box>
    </>
  )
}
