import React, { useEffect, useState } from "react"
import { Formik, Form, Field } from "formik"
import { Link as ReachLink } from "@reach/router"
import { ArrowBackIcon } from "@chakra-ui/icons"
import {
  Box,
  Button,
  Flex,
  SimpleGrid,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Select,
  Stack,
  useToast,
} from "@chakra-ui/react"

import SEO from "../seo"
import { firebase } from "../../../firebase/client"
import { PageLoader } from "../page-loader"
import { useAuth } from "../../providers/firebase/auth"

export function User({ id }) {
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [groups, setGroups] = useState([])
  const [formLoading, setFormLoading] = useState(false)
  const [userLoading, setUserLoading] = useState(false)
  const [revokeLoading, setRevokeLoading] = useState(false)
  const { isSuperAdmin, token } = useAuth()
  const toast = useToast()

  useEffect(() => {
    async function getUser() {
      try {
        const db = firebase.firestore()
        const user = await db.doc(`users/${id}`).get()

        // Get the path for the group ref
        const path = user.data().group.path
        const group = await db.doc(path).get()

        const payload = {
          ...user.data(),
          group: { ...group.data(), id: group.id },
        }

        setData(payload)
        setLoading(false)
      } catch (err) {
        console.error("There was a problem fetching the user", err)
        setLoading(false)
      }
    }

    getUser()
  }, [id])

  useEffect(() => {
    async function getGroups() {
      const db = firebase.firestore()

      try {
        const res = await db.collection("groups").get()
        const docs = res.docs.map(doc => {
          return {
            id: doc.id,
            ...doc.data(),
          }
        })

        setGroups(docs)
      } catch (err) {
        console.error("there was a problem", err)
      }
    }

    getGroups()
  }, [])

  // Handle Update
  async function handleUpdate(values) {
    const db = firebase.firestore()

    setFormLoading(true)

    try {
      await db.doc(`users/${id}`).set({
        ...values,
        group: values.group.id
          ? db.doc(`groups/${values.group.id}`)
          : db.doc(`groups/${values.group}`),
      })

      setFormLoading(false)
      toast({
        title: "Success!",
        description: "User updated successfully.",
        status: "success",
        duration: 9000,
        isClosable: true,
      })
    } catch (err) {
      console.error("Unable to update user", err)
      setFormLoading(false)
      toast({
        title: "Oops. There was a problem.",
        description:
          "There was a problem updating the user. Please try again and contact support if the error persists.",
        status: "error",
        duration: 9000,
        isClosable: true,
      })
    }
  }

  async function setUserClaim(isAdmin = true) {
    if (isAdmin) {
      setUserLoading(true)
    } else {
      setRevokeLoading(true)
    }

    const uid = id
    const payload = {
      customClaims: { admin: isAdmin, super_admin: false },
      uid,
    }

    try {
      const res = await fetch("/.netlify/functions/set-claims", {
        headers: { Authorization: token },
        method: "post",
        body: JSON.stringify(payload),
      })

      if (res.ok) {
        await res.json()
        setUserLoading(false)
        setRevokeLoading(false)

        toast({
          title: "Success!",
          description: "User updated successfully.",
          status: "success",
          duration: 9000,
          isClosable: true,
        })
      } else {
        console.error("there is an error", res)
        setUserLoading(false)
        setRevokeLoading(false)

        toast({
          title: "Oops. There was a problem.",
          description:
            "There was a problem updating the user. Please try again and contact support if the error persists.",
          status: "error",
          duration: 9000,
          isClosable: true,
        })
      }
    } catch (err) {
      console.error("there is an error", err)
      setUserLoading(false)
      setRevokeLoading(false)

      toast({
        title: "Oops. There was a problem.",
        description:
          "There was a problem updating the user. Please try again and contact support if the error persists.",
        status: "error",
        duration: 9000,
        isClosable: true,
      })
    }
  }

  if (loading || !data) {
    return <PageLoader />
  }

  const formikConfig = {
    onSubmit: values => handleUpdate(values),
    initialValues: {
      given_name: data.given_name,
      family_name: data.family_name,
      email: data.email,
      group: data.group,
      access_areas: data.access_areas,
    },
  }

  return (
    <>
      <SEO title="Micropore | User" />
      <Formik {...formikConfig}>
        {({ values, handleChange }) => (
          <Form>
            <Box p={8}>
              <Flex justify="space-between" mb={6}>
                <Box as="h2" fontSize={24} fontWeight="medium">
                  {data.given_name} {data.family_name}
                </Box>

                <Button
                  as={ReachLink}
                  to="/app/users"
                  leftIcon={<ArrowBackIcon />}
                >
                  Return to Users
                </Button>
              </Flex>
              <SimpleGrid columns={2} spacing={16}>
                <FormControl id="given_name">
                  <FormLabel htmlFor="given_name">Given Name</FormLabel>
                  <Input
                    type="text"
                    onChange={handleChange}
                    id="given_name"
                    name="given_name"
                    value={values.given_name}
                  />
                </FormControl>

                <FormControl id="family_name">
                  <FormLabel htmlFor="family_name">Family Name</FormLabel>
                  <Input
                    type="text"
                    onChange={handleChange}
                    id="family_name"
                    name="family_name"
                    value={values.family_name}
                  />
                </FormControl>

                <FormControl id="email">
                  <FormLabel htmlFor="email">Email</FormLabel>
                  <Input
                    type="text"
                    onChange={handleChange}
                    id="email"
                    name="email"
                    value={values.email}
                    disabled
                  />
                  <FormHelperText pt={2}>
                    Email Address can only be changed by the user via their
                    profile settings.
                  </FormHelperText>
                </FormControl>

                <FormControl id="group">
                  <FormLabel htmlFor="group">Group</FormLabel>
                  <Select
                    placeholder=""
                    name="group"
                    id="group"
                    onChange={handleChange}
                    value={values.group.id ? values.group.id : values.group}
                  >
                    <option value="" disabled>
                      Please Select
                    </option>
                    {groups.length > 0 &&
                      groups.map(group => (
                        <option key={group.id} value={group.id}>
                          {group.name}
                        </option>
                      ))}
                  </Select>
                </FormControl>

                <FormControl id="access_areas" mb={6}>
                  <FormLabel htmlFor="access_areas">Access</FormLabel>
                  <Stack spacing={10} direction="row">
                    <Flex>
                      <Box mr={4}>
                        <label htmlFor="access_areas_website">
                          <Field
                            id="access_areas_website"
                            type="checkbox"
                            name="access_areas"
                            value="website"
                            checked={values.access_areas.includes("website")}
                          />
                          &nbsp; Website
                        </label>
                      </Box>
                      <Box>
                        <label htmlFor="access_areas_dash">
                          <Field
                            id="access_areas_website"
                            type="checkbox"
                            name="access_areas"
                            value="dashboard"
                            checked={values.access_areas.includes("dashboard")}
                          />
                          &nbsp; Dashboard
                        </label>
                      </Box>
                    </Flex>
                  </Stack>
                </FormControl>
              </SimpleGrid>
            </Box>
            <Flex px={8}>
              <Button
                colorScheme="blue"
                type="submit"
                isLoading={formLoading}
                loadingText="Please wait..."
                mr={4}
              >
                Update User
              </Button>

              {isSuperAdmin && (
                <>
                  <Button
                    onClick={() => setUserClaim(true)}
                    colorScheme="green"
                    mr={4}
                    isLoading={userLoading}
                    loadingText="Updating User..."
                  >
                    Grant Admin Access
                  </Button>

                  <Button
                    onClick={() => setUserClaim(false)}
                    colorScheme="pink"
                    isLoading={revokeLoading}
                    loadingText="Updating User..."
                  >
                    Revoke Admin Access
                  </Button>
                </>
              )}
            </Flex>
          </Form>
        )}
      </Formik>
    </>
  )
}
