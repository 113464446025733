import React, { useState, useEffect } from "react"
import { useFormik } from "formik"
import {
  Box,
  Button,
  ScaleFade,
  Flex,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Text,
  VStack,
  Divider,
  SimpleGrid,
} from "@chakra-ui/react"

import { firebase } from "../../../firebase/client"
import { PageLoader } from "../page-loader"
import { navigate } from "gatsby"

export function RegisterForm({ invitationId }) {
  const [loading, setLoading] = useState(false)
  const [firebaseError, setFirebaseError] = useState(null)
  const [invitation, setInvitation] = useState(null)
  const [inviteError, showInviteError] = useState(false)
  const [pageLoading, setPageLoading] = useState(true)

  useEffect(() => {
    if (invitationId) {
      const db = firebase.firestore()
      const query = db.collection("invites").doc(invitationId)

      query
        .get()
        .then(snapshot => {
          const data = snapshot.data()

          if (data) {
            if (data.access_areas.includes("dashboard")) {
              setInvitation(data)
            } else {
              showInviteError(true)
            }
          } else {
            showInviteError(true)
          }

          setPageLoading(false)
        })
        .catch(err => {
          console.error("There was a problem retrieving the invitation", err)
          setLoading(false)
        })
    } else {
      showInviteError(true)
      setPageLoading(false)
    }
  }, [invitationId])

  async function login({ email, password, given_name, family_name }) {
    setLoading(true)
    setFirebaseError(null)

    // If the inputted email does not match the invite email
    if (invitation && email !== invitation.email) {
      setLoading(false)
      setFirebaseError(
        "The email address you have provided does not match the email address against your invitation. Please check your email address and try again."
      )

      return
    }

    // If the invite is a customer invitation
    // @TODO: Remove this logic, customer_invitations will be a new collection for customer
    // invites.
    if (invitation && invitation.customer_invite) {
      setLoading(false)
      setFirebaseError(
        "We are unable to complete the registration. Please contact a member of the support team."
      )

      return
    }

    // If your name's not on the list, your not coming in!
    const db = firebase.firestore()

    try {
      const res = await firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)

      await db.collection("invites").doc(invitationId).delete()

      const { uid } = res.user

      await db
        .collection("users")
        .doc(uid)
        .set({
          email,
          family_name,
          given_name,
          group: db.doc(`groups/${invitation.group.id}`),
          access_areas: invitation.access_areas,
        })

      await navigate("/app/dashboard")
    } catch (err) {
      console.error("there was an error: ", err)
      setLoading(false)
      setFirebaseError(err.message)
    }
  }

  const formik = useFormik({
    enableReinitialize: true,
    onSubmit: values => login(values),
    initialValues: {
      email: "",
      given_name: invitation ? invitation.given_name : "",
      family_name: invitation ? invitation.family_name : "",
      password: "",
    },
  })

  if (pageLoading) {
    return <PageLoader />
  }

  if (inviteError) {
    return (
      <Flex
        height="100vh"
        width="100%"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        backgroundColor="gray.20"
      >
        <Text color="red.500" fontSize={24}>
          This invitation has expired.
        </Text>
      </Flex>
    )
  }

  return (
    <Flex
      height="100vh"
      width="100%"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      backgroundColor="gray.20"
    >
      <Box mb={8}>
        <img src="/micropore-logo.png" alt="Micropore Logo" width={180} />
      </Box>

      <ScaleFade initialScale={0.9} in={true}>
        <Box
          width="500px"
          boxShadow="0px 0px 14px rgba(0, 0, 0, .15)"
          padding="40px"
          borderRadius="5px"
        >
          {firebaseError && (
            <Box
              bg="red.100"
              color="red.500"
              p={4}
              mb={4}
              borderRadius="5px"
              fontWeight="600"
            >
              {firebaseError}
            </Box>
          )}

          <form onSubmit={formik.handleSubmit}>
            <Text mb={4}>
              Welcome to the Micropore Website Dashboard. Please confirm your
              details below and click the register button when ready.
            </Text>

            <Divider mb={6} />

            <VStack spacing="24px">
              <SimpleGrid columns={2} spacing={4}>
                <FormControl id="given_name">
                  <FormLabel htmlFor="given_name">Your Given Name</FormLabel>
                  <Input
                    type="text"
                    onChange={formik.handleChange}
                    id="given_name"
                    name="given_name"
                    value={formik.values.given_name}
                    disabled={loading}
                  />
                </FormControl>
                <FormControl id="family_name">
                  <FormLabel htmlFor="family_name">Your Family Name</FormLabel>
                  <Input
                    type="text"
                    onChange={formik.handleChange}
                    id="family_name"
                    name="family_name"
                    value={formik.values.family_name}
                    disabled={loading}
                  />
                </FormControl>
              </SimpleGrid>

              <FormControl id="email">
                <FormLabel htmlFor="email">Your Email Address</FormLabel>
                <Input
                  type="email"
                  onChange={formik.handleChange}
                  id="email"
                  name="email"
                  value={formik.values.email}
                  disabled={loading}
                />
                <FormHelperText>
                  Your email address must match the email address that you
                  provided us to receive your invitation.
                </FormHelperText>
              </FormControl>

              <FormControl id="password">
                <FormLabel htmlFor="password">Password</FormLabel>
                <Input
                  type="password"
                  onChange={formik.handleChange}
                  id="password"
                  name="password"
                  value={formik.values.password}
                  disabled={loading}
                />
              </FormControl>

              <Button
                colorScheme="blue"
                color="white"
                size="md"
                isFullWidth
                type="submit"
                disabled={loading}
                isLoading={loading}
                loadingText="Please Wait"
              >
                Login
              </Button>
            </VStack>
          </form>
        </Box>
      </ScaleFade>
    </Flex>
  )
}
