/**
 * Pending Invitations Table
 */
import React, { useEffect, useState, useCallback } from "react"
import {
  Button,
  CircularProgress,
  Flex,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import { ArrowForwardIcon, DeleteIcon, WarningIcon } from "@chakra-ui/icons"
import { Table, Thead, Tr, Th, Td, Tbody } from "../table"
import { DeletePrompt } from "../prompt/DeletePrompt"
import { firebase } from "../../../firebase/client"
import { useAuth } from "../../providers/firebase/auth"
import { Link } from "gatsby"

export function UsersTable() {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [activeUser, setActiveUser] = useState(null)
  const [error, setError] = useState(false)
  const { isSuperAdmin, token } = useAuth()
  const { isOpen, onOpen, onClose } = useDisclosure()

  function openPrompt(user) {
    onOpen()
    setActiveUser(user)
  }

  function closePrompt() {
    onClose()
    setActiveUser(null)
  }

  const getData = useCallback(async () => {
    const db = firebase.firestore()

    try {
      const query = db.collection("users").get()
      const users = await query

      const userData = users.docs.map(async doc => {
        const user = doc.data()

        if (user.group) {
          const ref = user.group.path

          try {
            const res = await db.doc(ref).get()
            return {
              ...user,
              id: doc.id,
              group: {
                ...res.data(),
                id: res.id,
              },
            }
          } catch (err) {
            console.error("Unable to get user", err)
          }
        } else {
          return {
            ...user,
            id: doc.id,
          }
        }
      })

      Promise.all(userData).then(items => {
        setData(items)
        setLoading(false)
      })
    } catch (err) {
      setError(true)
      console.error("Unable to fetch data", err)
    }
  }, [])

  async function handleDelete() {
    const { id } = activeUser

    setLoading(true)

    // This needs to make a call to the netlify function
    // that will delete a user
    try {
      await fetch("/.netlify/functions/delete-user", {
        method: "delete",
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          uid: id,
        }),
      })

      closePrompt()
      await getData()
    } catch (err) {
      console.error("There was a problem deleting the record", err)
      setLoading(false)
      // Show toast
    }
  }

  useEffect(() => {
    async function _getData() {
      await getData()
    }

    _getData()

    return () => {}
  }, [getData])

  if (loading) {
    return (
      <Flex flexDir="column" justify="center" align="center">
        <CircularProgress isIndeterminate size={10} thickness={5} />
        <Text mt={4}>Fetching Users</Text>
      </Flex>
    )
  }

  if (error) {
    return (
      <Flex flexDir="column" justify="center" align="center">
        <WarningIcon color="red.500" fontSize={32} mb={4} />
        <Text
          color="red.500"
          fontSize={18}
          fontWeight="medium"
          textAlign="center"
        >
          There was a problem retrieving the users.
          <br />
          Please reload the page and try again.
        </Text>
      </Flex>
    )
  }

  if (data.length === 0) {
    return <Text>There are no users</Text>
  }

  return (
    <>
      <Table>
        <Thead>
          <Tr>
            <Th>ID</Th>
            <Th>Given Name</Th>
            <Th>Family Name</Th>
            <Th>Email</Th>
            <Th>Group</Th>
            <Th>Areas</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>

        <Tbody>
          {data.map(user => {
            return (
              <Tr key={user.id}>
                <Td>{user.id}</Td>
                <Td>{user.given_name}</Td>
                <Td>{user.family_name}</Td>
                <Td>{user.email}</Td>
                <Td>
                  {user.group && user.group.name
                    ? user.group.name
                    : "Not Assigned"}
                </Td>
                <Td>
                  {user.access_areas.map(area => (
                    <Text key={area} textTransform="capitalize">
                      {area}
                    </Text>
                  ))}
                </Td>
                <Td>
                  <Flex justifyContent="space-between">
                    <Button
                      colorScheme="blue"
                      rightIcon={<ArrowForwardIcon />}
                      as={Link}
                      to={`/app/user/${user.id}`}
                      mr={2}
                    >
                      View
                    </Button>

                    {isSuperAdmin && (
                      <Button
                        colorScheme="pink"
                        rightIcon={<DeleteIcon />}
                        onClick={() => openPrompt(user)}
                      >
                        Delete
                      </Button>
                    )}
                  </Flex>
                </Td>
              </Tr>
            )
          })}
        </Tbody>
      </Table>

      {activeUser && (
        <DeletePrompt
          isOpen={isOpen}
          onClose={closePrompt}
          title="Are you sure?"
          handleOk={handleDelete}
        >
          Are you sure you want to remove {activeUser.given_name}{" "}
          {activeUser.family_name} from the system?
        </DeletePrompt>
      )}
    </>
  )
}
