import * as React from "react"
import { useEffect, useState } from "react"
import { Box, SimpleGrid } from "@chakra-ui/react"
import { DetailsForm } from "../forms/details-form"
import { EmailForm } from "../forms/email-form"
import { firebase } from "../../../firebase/client"

export const ProfilePage = () => {
  const [values, setValues] = useState()

  useEffect(() => {
    const user = firebase.auth().currentUser
    const db = firebase.firestore()
    const userRef = db.doc("users/" + user.uid)

    userRef
      .get()
      .then(doc => {
        const data = doc.data()
        setValues(data)
      })
      .catch(err => {
        console.log("This is the err", { err })
      })
  }, [])

  return (
    <>
      <SimpleGrid columns={2} columnGap={16} px={12} py={12}>
        <Box>
          <EmailForm />
        </Box>

        <Box>
          <DetailsForm
            defaultValues={
              values
                ? {
                    given_name: values.given_name,
                    family_name: values.family_name,
                  }
                : {}
            }
          />
        </Box>
      </SimpleGrid>
    </>
  )
}
