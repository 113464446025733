/**
 * User Registration Form
 */
import React from "react"
import queryString from "query-string"
import { RegisterForm } from "../components/forms/register"
import SEO from "../components/seo"

function Register({ location }) {
  function getInviteId() {
    const params = queryString.parse(location.search)
    return params.invitation
  }

  return (
    <>
      <SEO title="Micropore | Register" />
      <RegisterForm invitationId={getInviteId()} />
    </>
  )
}

export default Register
