/**
 * Email Form
 * Use this form to let users set or update their email address
 */

import * as React from "react"
import { useFormik } from "formik"
import {
  FormControl,
  FormLabel,
  Input,
  Text,
  Button,
  Heading,
  useToast,
} from "@chakra-ui/react"
import { firebase } from "../../../firebase/client"
import { useAuth } from "../../providers/firebase/auth"

export const EmailForm = ({ defaultValue }) => {
  const toast = useToast()
  const { user } = useAuth()
  const [loading, setLoading] = React.useState(false)

  const handleSubmit = values => {
    const user = firebase.auth().currentUser
    const db = firebase.firestore()
    const userRef = db.doc("users/" + user.uid)

    const toastError = ({ message = undefined }) => {
      setLoading(false)

      toast({
        title: "Oops there was a problem.",
        description:
          message ||
          "Sorry we were unable to update your email address. Please check the details and try again.",
        status: "error",
        duration: 9000,
        isClosable: true,
      })
    }

    setLoading(true)

    user
      .updateEmail(values.email)
      .then(() => {
        userRef
          .update({ email: values.email })
          .then(() => {
            toast({
              title: "Email address updated.",
              description: "Your email address was updated successfully.",
              status: "success",
              duration: 9000,
              isClosable: true,
            })

            setLoading(false)
          })
          .catch(err => {
            console.error("updateEmail() error:", err)

            if (err.code === "auth/requires-recent-login") {
              toastError({ message: err.message })
            } else {
              toastError()
            }
          })
      })
      .catch(err => {
        console.error("updateEmail() error:", err)

        if (err.code === "auth/requires-recent-login") {
          toastError({ message: err.message })
        } else {
          toastError()
        }
      })
  }

  const formik = useFormik({
    initialValues: {
      email: user.email,
    },
    onSubmit: values => handleSubmit(values),
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <Heading as="h2" fontSize={20} color="blue.500" mb={4}>
        Your Email Address
      </Heading>
      <Text
        backgroundColor="gray.100"
        mb={6}
        fontSize={16}
        borderLeftWidth={3}
        borderLeftColor="blue.400"
        px={5}
        py={2}
      >
        To update your email address please enter your new email address in the
        text field below and click the update email address button.
      </Text>
      <FormControl mb={6}>
        <FormLabel htmlFor="email">Email address</FormLabel>
        <Input
          type="email"
          onChange={formik.handleChange}
          id="email"
          name="email"
          value={formik.values.email}
        />
      </FormControl>

      <FormControl>
        <Button
          colorScheme="blue"
          type="submit"
          isLoading={loading}
          loadingText="Please wait"
        >
          Update email address
        </Button>
      </FormControl>
    </form>
  )
}
