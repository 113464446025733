/**
 * Sanity Helper Methods
 */
import SanityClient from "@sanity/client"
import sanityConfig from "../../sanity.config"

/**
 * Get Sanity Client
 */
export const client = SanityClient({
  projectId: sanityConfig.projectId,
  dataset: sanityConfig.dataset,
  useCdn: false,
  withCredentials: true,
})
