import React from "react"
import { Flex, CircularProgress } from "@chakra-ui/react"

export function PageLoader() {
  return (
    <Flex
      height="100vh"
      width="100%"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      backgroundColor="gray.20"
    >
      <CircularProgress isIndeterminate size={20} thickness={5} />
    </Flex>
  )
}
