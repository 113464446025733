/**
 * Pending Invitations Table
 */
import React, { useCallback, useEffect, useState } from "react"
import { ArrowForwardIcon, DeleteIcon } from "@chakra-ui/icons"
import { Link } from "@reach/router"
import {
  Button,
  CircularProgress,
  Flex,
  Text,
  useDisclosure,
} from "@chakra-ui/react"
import { Table, Thead, Tr, Th, Td, Tbody } from "../table"
import { firebase } from "../../../firebase/client"
import { useAuth } from "../../providers/firebase/auth"
import { DeletePrompt } from "../prompt/DeletePrompt"

export function GroupsTable() {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [activeGroup, setActiveGroup] = useState(null)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { isAdmin } = useAuth()

  function openPrompt(group) {
    onOpen()
    setActiveGroup(group)
  }

  function closePrompt() {
    onClose()
    setActiveGroup(null)
  }

  async function handleDelete() {
    const db = firebase.firestore()
    const { id } = activeGroup

    setLoading(true)

    try {
      await db.collection("groups").doc(id).delete()
      onClose()
      await getData()
    } catch (err) {
      console.error("There was a problem deleting the record", err)
      setLoading(false)
    }
  }

  const getData = useCallback(async () => {
    const db = firebase.firestore()

    try {
      const query = db.collection("groups").get()
      const groups = await query
      const groupData = []

      groups.forEach(doc => {
        groupData.push({
          ...doc.data(),
          id: doc.id,
        })
      })

      setData(groupData)
      setLoading(false)
    } catch (err) {
      console.error("Unable to fetch data", err)
    }
  }, [])

  useEffect(() => {
    async function _getData() {
      await getData()
    }

    _getData()

    return () => {}
  }, [getData])

  if (loading) {
    return (
      <Flex flexDir="column" justify="center" align="center">
        <CircularProgress isIndeterminate size={10} thickness={5} />
        <Text mt={4}>Fetching Groups</Text>
      </Flex>
    )
  }

  if (data.length === 0) {
    return <Text>There are no groups</Text>
  }

  return (
    <>
      <Table>
        <Thead>
          <Tr>
            <Th>ID</Th>
            <Th>Group Name</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>

        <Tbody>
          {data.map(group => {
            return (
              <Tr key={group.id}>
                <Td>{group.id}</Td>
                <Td>{group.name}</Td>
                <Td>
                  <Flex>
                    <Button
                      as={Link}
                      to={`/app/group/${group.id}`}
                      colorScheme="blue"
                      rightIcon={<ArrowForwardIcon />}
                      mr={4}
                    >
                      View
                    </Button>

                    {isAdmin && (
                      <Button
                        colorScheme="pink"
                        rightIcon={<DeleteIcon />}
                        onClick={() => openPrompt(group)}
                      >
                        Delete
                      </Button>
                    )}
                  </Flex>
                </Td>
              </Tr>
            )
          })}
        </Tbody>
      </Table>

      {activeGroup && (
        <DeletePrompt
          isOpen={isOpen}
          onClose={closePrompt}
          title="Are you sure?"
          handleOk={handleDelete}
        >
          Are you sure you want to delete the {activeGroup.name} group? This
          action can not be undone.
        </DeletePrompt>
      )}
    </>
  )
}
