import * as React from "react"
import { useFormik } from "formik"
import {
  useToast,
  SimpleGrid,
  FormControl,
  Input,
  FormLabel,
  Button,
  Heading,
} from "@chakra-ui/react"

import { firebase } from "../../../firebase/client"

export const DetailsForm = ({ defaultValues }) => {
  const toast = useToast()
  const [loading, setLoading] = React.useState(false)

  const handleSubmit = values => {
    const user = firebase.auth().currentUser
    const db = firebase.firestore()
    const userRef = db.doc("users/" + user.uid)

    const toastError = () => {
      setLoading(false)
      toast({
        title: "Oops there was a problem.",
        description:
          "Sorry we were unable to update your details. Please check the information and try again.",
        status: "error",
        duration: 9000,
        isClosable: true,
      })
    }

    setLoading(true)

    user
      .updateProfile({
        displayName: `${values.given_name} ${values.family_name}`,
      })
      .then(() => {
        userRef
          .update({
            given_name: values.given_name,
            family_name: values.family_name,
          })
          .then(() => {
            toast({
              title: "Personal details updated.",
              description: "Your personal details were updated successfully.",
              status: "success",
              duration: 9000,
              isClosable: true,
            })

            setLoading(false)
          })
          .catch(err => {
            console.error("updateEmail() error:", err)
            toastError()
          })
      })
      .catch(err => {
        console.error("updateEmail() error:", err)
        toastError()
      })
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      family_name: defaultValues.family_name,
      given_name: defaultValues.given_name,
    },
    onSubmit: values => handleSubmit(values),
  })

  return (
    <form onSubmit={formik.handleSubmit}>
      <Heading as="h2" fontSize={20} color="blue.500" mb={4}>
        Your Details
      </Heading>

      <SimpleGrid
        columnGap={4}
        columns={{ base: 1, md: 2 }}
        mb={{ base: 6, md: 2 }}
      >
        <FormControl mb={4}>
          <FormLabel htmlFor="given_name">Given name</FormLabel>
          <Input
            type="text"
            onChange={formik.handleChange}
            id="given_name"
            name="given_name"
            value={formik.values.given_name}
          />
        </FormControl>

        <FormControl>
          <FormLabel htmlFor="family_name">Family name</FormLabel>
          <Input
            type="text"
            onChange={formik.handleChange}
            id="family_name"
            name="family_name"
            value={formik.values.family_name}
          />
        </FormControl>
      </SimpleGrid>

      <Button
        colorScheme="blue"
        type="submit"
        isLoading={loading}
        loadingText="Please wait"
      >
        Update personal details
      </Button>
    </form>
  )
}
