import React, { useEffect, useState } from "react"
import { Formik, Form } from "formik"
import { Link as ReachLink } from "@reach/router"
import { ArrowBackIcon } from "@chakra-ui/icons"
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  VStack,
  Textarea,
  useToast,
} from "@chakra-ui/react"

import SEO from "../seo"
import { firebase } from "../../../firebase/client"
import { PageLoader } from "../page-loader"
import { PageLookup } from "../sanity/PageLookup"

export function Group({ id }) {
  const toast = useToast()
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [formLoading, setFormLoading] = useState(false)
  const [pages, setPages] = useState([])

  useEffect(() => {
    async function getGroup() {
      try {
        const db = firebase.firestore()
        const group = await db.doc(`groups/${id}`).get()
        const payload = { ...group.data() }

        setData(payload)
        setLoading(false)
      } catch (err) {
        console.error("There was a problem fetching the user", err)
        setLoading(false)
      }
    }

    getGroup()
  }, [id])

  // Handle Update
  async function handleUpdate(values) {
    const payload = {
      ...values,
      allowedPages: pages,
    }

    const db = firebase.firestore()

    setFormLoading(true)

    try {
      await db.doc(`groups/${id}`).set({
        ...payload,
      })

      setFormLoading(false)

      toast({
        title: "Success!",
        description: "Group updated successfully.",
        status: "success",
        duration: 9000,
        isClosable: true,
      })
    } catch (err) {
      console.error("Unable to update user", err)
      setFormLoading(false)

      toast({
        title: "Error",
        description: "Unable to update group. Please try again.",
        status: "error",
        duration: 9000,
        isClosable: true,
      })
    }
  }

  if (loading || !data) {
    return <PageLoader />
  }

  const formikConfig = {
    onSubmit: values => handleUpdate(values),
    initialValues: {
      name: data.name,
      description: data.description,
    },
  }

  return (
    <>
      <SEO title="Micropore | Group" />
      <Formik {...formikConfig}>
        {({ values, handleChange }) => (
          <Form>
            <Box p={8}>
              <Flex justify="space-between" mb={6}>
                <Box as="h2" fontSize={24} fontWeight="medium">
                  {data.name}
                </Box>

                <Button
                  as={ReachLink}
                  to="/app/groups"
                  leftIcon={<ArrowBackIcon />}
                >
                  Go Back to Groups
                </Button>
              </Flex>
            </Box>
            <VStack spacing="24px" px={8} pb={8}>
              <FormControl id="name">
                <FormLabel htmlFor="name">Group Name</FormLabel>
                <FormHelperText mb={4}>
                  What is the name of the group?
                </FormHelperText>
                <Input
                  type="text"
                  onChange={handleChange}
                  id="name"
                  name="name"
                  value={values.name}
                />
              </FormControl>

              <FormControl id="description">
                <FormLabel htmlFor="description">Description</FormLabel>
                <FormHelperText mb={4}>
                  Enter a description about the group.
                </FormHelperText>
                <Textarea
                  onChange={handleChange}
                  id="description"
                  name="description"
                  value={values.description}
                />
              </FormControl>

              <FormControl id="pages">
                <PageLookup
                  presetPages={data.allowedPages || []}
                  selectionCallback={newPages => setPages(newPages)}
                />
              </FormControl>
            </VStack>
            <Flex px={8}>
              <Button
                colorScheme="blue"
                type="submit"
                isLoading={formLoading}
                loadingText="Please wait..."
                mr={4}
              >
                Update Group
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </>
  )
}
