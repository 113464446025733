import React, { useState } from "react"
import {
  Box,
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  Flex,
  useDisclosure,
} from "@chakra-ui/react"
import SEO from "../seo"
import { GroupsTable } from "../data-table/Groups"
import { CreateGroupForm } from "../forms/create-group"
import { PageLoader } from "../page-loader"

export function Groups() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [loading, setLoading] = useState()

  function handleComplete() {
    setLoading(true)
    onClose()

    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }

  if (loading) return <PageLoader />

  return (
    <>
      <SEO title="Micropore | Groups" />

      <Box p={8}>
        <Flex justify="space-between" mb={6}>
          <Box as="h2" fontSize={24} fontWeight="medium">
            Groups
          </Box>

          <Button colorScheme="blue" onClick={onOpen}>
            Create Group
          </Button>
        </Flex>

        <GroupsTable />
      </Box>

      <Drawer placement="left" onClose={onClose} isOpen={isOpen} size="md">
        <DrawerOverlay>
          <DrawerContent>
            <DrawerHeader borderBottomWidth="1px">Create Group</DrawerHeader>
            <DrawerBody>
              <CreateGroupForm onComplete={handleComplete} />
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
  )
}
